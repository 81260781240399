import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { useContext, useState, useEffect } from "react";
import { AppsContext } from "../../../App";
import logoDefault from "../../../../src/resources/static/assets/images/logo-default.png";

const useStyles = makeStyles((theme) => ({
    cardGrid: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
    /* card: {
    [theme.breakpoints.up("sm")]: {
      width: 400,
    },
    [theme.breakpoints.down("sm")]: {
      width: 300,
    },
    height: 80,
    display: "flex",
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 80,
  }, */
    card: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    cardDetails: {
        flex: 1,
    },
    containerMedia: {
        display: "flex",
        alignItem: "center",
        justifyContent: "center",
    },
    cardMedia: {
        //paddingTop: "56.25%", // 16:9
    },
    cardMediaDefault: {
        marginTop: "20px",
        width: "auto",
        maxHeight: "200px",
    },
    cardContent: {
        flexGrow: 1,
        paddingBottom: "0px",
    },
    contentTitle: {},
    contentDescription: {},
    contentInfo: {},
}));

export default function ContentCategory(props) {
    const classes = useStyles();
    //const [clicked, setClicked] = useState(false);
    const { content } = props;
    const { state, dispatch } = useContext(AppsContext);
    const counter = state.apps.length - 1;
    const language = state.apps[counter].language;
    const navigation = state.apps[counter].navigation;
    const idSelectedDigitalMenu = state.apps[counter].idSelectedDigitalMenu;
    const idSelectedCategory = state.apps[counter].idSelectedCategory;
    const idSelectedProduct = state.apps[counter].idSelectedProduct;
    const imageUrl = content.categoryImageURL;
    const defaultImageUrl = logoDefault;
    const [imgSrc, setImgSrc] = useState(imageUrl);
    const [isDefault, setIsDefault] = useState(false);

    useEffect(() => {
        const checkImage = async (url) => {
            try {
                const response = await fetch(url, { method: "HEAD" });
                if (
                    response.ok &&
                    response.headers.get("Content-Type").startsWith("image/")
                ) {
                    setImgSrc(url);
                    setIsDefault(false);
                } else {
                    setImgSrc(defaultImageUrl);
                    setIsDefault(true);
                }
            } catch (error) {
                setImgSrc(defaultImageUrl);
                setIsDefault(true);
            }
        };

        checkImage(imageUrl);
    }, [defaultImageUrl, imageUrl]);

    const handleListProducts = (categoryCode) => () => {
        const nav = "PRODUCT";
        const category = categoryCode;
        dispatch({
            type: "add",
            payload: {
                id: counter + 1,
                language: language,
                navigation: nav,
                idSelectedDigitalMenu: idSelectedDigitalMenu,
                idSelectedCategory: category,
                idSelectedProduct: idSelectedProduct,
            },
        });
        props.functionListProductsChild(categoryCode);
    };

    return (
        <>
            <Container className={classes.cardGrid} maxWidth="sm">
                <Grid item>
                    {/*  <CardActionArea component={Link} to={`${url}/products`}> */}
                    <CardActionArea>
                        <Card
                            className={classes.card}
                            onClick={handleListProducts(content.categoryCode)}
                        >
                            <div className={classes.containerMedia}>
                                <CardMedia
                                    className={
                                        isDefault
                                            ? classes.cardMediaDefault
                                            : classes.cardMedia
                                    }
                                    component="img"
                                    image={imgSrc}
                                />
                            </div>
                            <div className={classes.cardDetails}>
                                <CardContent>
                                    <div className={classes.contentTitle}>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                        >
                                            {
                                                content[
                                                    "categoryDescription" +
                                                        language
                                                ]
                                            }
                                        </Typography>
                                    </div>
                                    {/*                 <div className={classes.contentDescription}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    {content.categoryDescriptionIT}
                  </Typography>
                </div>
                <div className={classes.contentInfo}></div> */}
                                </CardContent>
                            </div>
                        </Card>
                    </CardActionArea>
                </Grid>
            </Container>
        </>
    );
}

ContentCategory.propTypes = {
    content: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        image: PropTypes.string,
    }),
};
