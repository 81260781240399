import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { useContext, useState, useEffect } from "react";
import { AppsContext } from "../../../App";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import logoDefault from "../../../../src/resources/static/assets/images/logo-default.png";

const useStyles = makeStyles((theme) => ({
    cardGrid: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(4),
    },
    card: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
    },
    cardDetails: {
        flex: 1,
    },
    containerMedia: {
        display: "flex",
        alignItem: "center",
        justifyContent: "center",
    },
    cardMedia: {
        //paddingTop: "56.25%", // 16:9
        marginTop: "20px",
        width: "auto",
        maxHeight: "200px",
    },
    cardContent: {
        flexGrow: 1,
        paddingBottom: "0px",
    },
    contentTitle: {},
    contentDescription: {},
    contentPrice: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(-2),
    },
    custom: {
        textDecoration: "line-through",
        textDecorationColor: "red",
        //letterSpacing: "20px",
        //fontSize: "20px",
        fontStyle: "italic",
        //color: "#00EE00",
        //fontWeight: "bold",
    },
}));

export default function ContentProduct(props) {
    const classes = useStyles();
    const { content } = props;
    const { state, dispatch } = useContext(AppsContext);
    const counter = state.apps.length - 1;
    const language = state.apps[counter].language;
    const navigation = state.apps[counter].navigation;
    const idSelectedDigitalMenu = state.apps[counter].idSelectedDigitalMenu;
    const idSelectedCategory = state.apps[counter].idSelectedCategory;
    const idSelectedProduct = state.apps[counter].idSelectedProduct;
    const productHiddenPrice = props.digitalMenuHiddenPrices;
    const productForceVisiblePrice = content.productForceVisiblePrice;
    const productUnitCode = content.productUnitCode;
    const productUnits = props.units;
    const productUnitResult = productUnits.find(
        (unit) => unit.unitCode === productUnitCode
    );
    const imageUrl = content.productImageURL;
    const defaultImageUrl = logoDefault;
    const [imgSrc, setImgSrc] = useState(imageUrl);

    useEffect(() => {
        const checkImage = async (url) => {
            try {
                const response = await fetch(url, { method: "HEAD" });
                if (
                    response.ok &&
                    response.headers.get("Content-Type").startsWith("image/")
                ) {
                    setImgSrc(url);
                } else {
                    setImgSrc(defaultImageUrl);
                }
            } catch (error) {
                setImgSrc(defaultImageUrl);
            }
        };

        checkImage(imageUrl);
    }, [defaultImageUrl, imageUrl]);

    const handleDetailProduct = (product, productCode) => () => {
        const nav = "DETAIL";
        const prod = productCode;
        dispatch({
            type: "add",
            payload: {
                id: counter + 1,
                language: language,
                navigation: nav,
                idSelectedDigitalMenu: idSelectedDigitalMenu,
                idSelectedCategory: idSelectedCategory,
                idSelectedProduct: prod,
            },
        });
        props.functionDetailProductChild(product);
    };

    return (
        <>
            <Container className={classes.cardGrid} maxWidth="sm">
                <Grid item>
                    <CardActionArea>
                        <Card
                            className={classes.card}
                            onClick={handleDetailProduct(
                                content,
                                content.productCode
                            )}
                        >
                            <div className={classes.containerMedia}>
                                <CardMedia
                                    className={classes.cardMedia}
                                    component="img"
                                    image={imgSrc}
                                />
                            </div>
                            <div className={classes.cardDetails}>
                                <CardContent className={classes.cardContent}>
                                    <div className={classes.contentTitle}>
                                        <Typography
                                            variant="subtitle2"
                                            component="div"
                                            gutterBottom
                                        >
                                            {content["productTitle" + language]}
                                        </Typography>
                                    </div>

                                    <div className={classes.contentPrice}>
                                        {productHiddenPrice &&
                                        !productForceVisiblePrice ? null : content.productDiscountedPrice ? (
                                            <>
                                                <Typography
                                                    variant="subtitle2"
                                                    component="div"
                                                    className={classes.custom}
                                                    gutterBottom
                                                >
                                                    {"€ "}
                                                    {content.productPrice
                                                        ? content.productPrice.toLocaleString(
                                                              "it-IT",
                                                              {
                                                                  minimumFractionDigits: 2,
                                                              }
                                                          )
                                                        : "0,00"}
                                                </Typography>
                                                <Typography
                                                    variant="subtitle2"
                                                    component="div"
                                                >
                                                    {"€ "}
                                                    {content.productDiscountedPrice
                                                        ? content.productDiscountedPrice.toLocaleString(
                                                              "it-IT",
                                                              {
                                                                  minimumFractionDigits: 2,
                                                              }
                                                          )
                                                        : "0,00"}
                                                </Typography>
                                            </>
                                        ) : (
                                            <Typography
                                                variant="h6"
                                                component="div"
                                            >
                                                {"€ "}
                                                {content.productPrice
                                                    ? content.productPrice.toLocaleString(
                                                          "it-IT",
                                                          {
                                                              minimumFractionDigits: 2,
                                                          }
                                                      )
                                                    : "0,00"}
                                            </Typography>
                                        )}

                                        {/* <Typography variant="h6" component="div">
                                    {productUnitResult.unitDescription}
                                </Typography> */}
                                    </div>
                                </CardContent>
                            </div>
                        </Card>
                    </CardActionArea>
                </Grid>
            </Container>
        </>
    );
}

ContentProduct.propTypes = {
    content: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        ingredients: PropTypes.string,
        price: PropTypes.string,
        unit: PropTypes.string,
        image: PropTypes.string,
    }),
};
